!<template>
  <div class="whereDelivery">
    <div class="whereContent content_area">
      <div class="clearfix top">
        <al-image
          src="https://attached-file.oss-cn-shanghai.aliyuncs.com/ailaworldImgs/siffa/where/whereBanner.png"
          class="bannerImg fl"
        ></al-image>
        <div class="fl collaTxt">
          <div class="title">“运筹帷幄 领航出海”</div>
          <div class="title">2021 新国际物流生态峰会</div>
          <div class="title color">GLOBAL LOGISTICS SUMMIT</div>
          <div
            v-for="(it, index) in unitList"
            :key="index"
            class="collaRight clearfix"
          >
            <div class="textTitle fl">{{ it.title }}</div>
            <div class="textMain fl">{{ it.text }}</div>
          </div>
        </div>
      </div>
      <div class="textData">
        <div v-for="(it, index) in textList" :key="index">{{ it.text }}</div>
      </div>
    </div>
    <div class="content_area">
      <div class="clearfix meetingDetail">
        <div class="fl">会议详情</div>
        <div class="fr cursor">
          <span v-if="packup" @click="packup = !packup"
            ><span class="span"><i class="el-icon-arrow-down txt"></i></span
            >{{ $t("open") }}</span
          >
          <span v-else @click="packup = !packup"
            ><span class="span"><i class="el-icon-arrow-up txt"></i></span
            >{{ $t("packup") }}</span
          >
        </div>
      </div>
      <div v-if="!packup">
          <meetDetail></meetDetail>
      </div>
    </div>
  </div>
</template>

<script>
import alImage from "../../../../baseComponents/alImage.vue";
import meetDetail from "~xif/components/whereDelivery/detail"
export default { 
  components: { alImage,meetDetail },
  data() {
    return {
      unitList: [
        {
          title: "会议指导单位：",
          text:
            "中国交通通信信息中心、上海组合港管理委员会办公室、中国物流与采购联合会",
        },
        {
          title: "主办单位：",
          text: "运去哪",
        },
        {
          title: "联合主办单位：",
          text:
            "国家交通运输物流公共信息平台、国家水上交通信息服务平台、上海国际航运中心发展促进会",
        },
        {
          title: "支持单位：",
          text: "中国国际货运代理协会、WIFFA国际货代信用合作平台",
        },
        {
          title: "合作单位：",
          text: "上海国际航运研究中心、AiLa、大赢家",
        },
      ],
      textList: [
        {
          text:
            "这是最坏的时代，世界格局依然充满不确定性，即使传承百年的企业也要经受生死考验。",
        },
        {
          text:
            "这是最好的时代，未知的数字时代，传统的航业固有格局正在松动，全新的挑战与征程正在开启。",
        },
        {
          text: "从规划：",
        },
        {
          text: "如何界定数字化航运时代的探索与布局 ？",
        },
        {
          text: "传统货代行业角色如何重新定位？",
        },
        {
          text: "如何解决效率与创新的平衡？",
        },
        {
          text: "如何重塑业界模式？",
        },
        {
          text: "到实践：",
        },
        {
          text: "如何选型货代运业提供的数字化产品/服务？",
        },
        {
          text: "如何打造赋能中台的革新航运生态？",
        },
        {
          text: "实施过程需要回避的“坑”有哪些？",
        },
        {
          text: "如何制定物流业运营规则？",
        },
        {
          text: "怎样面对这次变局？全球物流趋势将如何演变？",
        },
        {
          text:
            "5月25日，运去哪运筹帷幄，领航出海新国际物流生态峰会，似乎给了我们期待已久的答案。让我们来一起回顾这样翻动业界的物流生态峰会。",
        },
      ],
      packup: false,
    };
  },
};
</script>

<style lang="less" scoped>
.whereDelivery {
  width: 100%;
  background: #fafafa;
  .whereContent {
    background: #fff;
    height: 800px;
    margin: 24px auto;
    padding: 30px 24px;
    box-shadow: 0px 2px 15px 0px rgba(0, 0, 0, 0.17),
      0px 2px 4px 0px rgba(0, 0, 0, 0.11);
    .top {
      padding-bottom: 32px;
      border-bottom: 1px solid #dcdfe6;
    }
    .bannerImg {
      width: 562px;
      height: 340px;
    }
    .collaTxt {
      margin-left: 24px;
      .title {
        font-size: 24px;
        font-weight: bold;
      }
      .color {
        color: #b5b4b4;
        font-size: 20px;
      }
    }
    .collaRight {
      width: 560px;
      line-height: 30px;
      margin-top: 10px;
      .textTitle {
        max-width: 150px;
        color: #666;
      }
      .textMain {
        max-width: 410px;
        color: #3c4a55;
        font-weight: bold;
      }
    }
    .textData {
      padding: 20px 0;
      div {
        color: #666;
        line-height: 24px;
      }
    }
  }
  .meetingDetail {
    margin-bottom: 12px;
   .fl{
       font-size: 22px;
   }
    .fr {
      color: #999999;
      margin-top: 10px;
      .span {
        background: #d8d8d8;
        padding: 0 3px;
        border-radius: 50%;
        margin-right: 8px;
        .txt {
          color: #fff;
        }
      }
    }
  }
}
</style>