<template>
  <div class="detail tc">
    <div v-for="(it, index) in list1" :key="index">
      <el-image class="width1" :src="it" :preview-src-list="srcList"></el-image>
    </div>
    <div class="detailContent">
      <div v-for="(it, index) in list2" :key="index">
        <div v-if="it.title" class="title">{{ it.title }}</div>
        <template v-if="it.txtList"
          ><div v-for="item in it.txtList" class="text">
            {{ item }}
          </div></template
        >
        <template v-if="it.imgList"
          ><div v-for="its in it.imgList" class="img">
            <el-image
              :src="its"
              class="width2"
              :preview-src-list="srcList"
            ></el-image></div
        ></template>
        <template v-if="it.videoId">
          <div class="palyVideo">
            <al-image
              :fit="'cover'"
              style="width: 800px; height: 450px"
              :src="it.coverUrl"
              class="coverImg"
            ></al-image>
            <div class="mark">
              <i
                class="el-icon-video-play cursor"
                @click="playVideo(it.videoId)"
              ></i>
            </div>
          </div>
        </template>
      </div>
    </div>
    <el-dialog title="提示" :visible.sync="dialogVisible" width="500px">
      <div class="tl tips">
        抱歉，您没有观看权限，成为会员可观看完整视频哦！
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button @click="dialogVisible = false" size="small">取 消</el-button>
        <el-button
          type="primary"
          size="small"
          @click="$router.push('/memberShip')"
          >申请入会</el-button
        >
      </span>
    </el-dialog>
    <videoPlay ref="playVideo"></videoPlay>
  </div>
</template>

<script>
import videoPlay from "@/baseComponents/videoPlay";

export default {
  components: {
    videoPlay,
  },
  data() {
    return {
      srcList: [],
      dialogVisible: false,
      list1: [
        "https://attached-file.oss-cn-shanghai.aliyuncs.com/ailaworldImgs/siffa/where/where7.png",
        "https://attached-file.oss-cn-shanghai.aliyuncs.com/ailaworldImgs/siffa/where/where8.png",
        "https://attached-file.oss-cn-shanghai.aliyuncs.com/ailaworldImgs/siffa/where/where9.png",
        "https://attached-file.oss-cn-shanghai.aliyuncs.com/ailaworldImgs/siffa/where/where10.png",
        "https://attached-file.oss-cn-shanghai.aliyuncs.com/ailaworldImgs/siffa/where/where11.png",
      ],
      list2: [
        {
          title: "大会议程",
          txtList: ["9:00-9:30 嘉宾、媒体签到", "9:30-9:50 领导致辞和主题发言"],
          videoId: "5ff190e9e1cd40fda94df8e6f93b1420",
          coverUrl:
            "https://vod.aila.site/5ff190e9e1cd40fda94df8e6f93b1420/snapshots/3dc075ccf05b4992bc8d36d482a4d8f3-00005.jpg",
        },
        {
          title: "时代篇",
          txtList: [
            "9:50-10:10 探索智慧港口转型升级之路",
            "嘉宾：徐青青 招商局港口战略创新部总经理",
          ],
          imgList: [
            "https://attached-file.oss-cn-shanghai.aliyuncs.com/ailaworldImgs/siffa/where/where13.jpg",
          ],
        },
        {
          txtList: [
            "10:10-10:30 《国际物流产业数字化发展报告》解读",
            "嘉宾：徐凯 上海国际航运研究中心首席信息官",
          ],
          imgList: [
            "https://attached-file.oss-cn-shanghai.aliyuncs.com/ailaworldImgs/siffa/where/where12.png",
            "https://attached-file.oss-cn-shanghai.aliyuncs.com/ailaworldImgs/siffa/where/where.jpg",
          ],
        },
        {
          txtList: [
            "会议现场，也重磅发布了《国际物流产业数字化发展报告》。该报告由上海国际航运研究中心、中国交通通信信息中心、中国国际货运代理协会、运去哪共同整理并发布，历时半年时间，走访调研了国际物流行业近千家企业，从国际物流整体市场环境、国际物流数字化现状及未来趋势等层面，全方位解析了国际物流产业面临的机遇与挑战。",
          ],
          videoId: "0a2e1b0dac6742498ba7b05914563769",
          coverUrl:
            "https://vod.aila.site/0a2e1b0dac6742498ba7b05914563769/snapshots/896dbbe4ab9242b68484fd7577d79080-00005.jpg",
        },
        {
          title: "科技篇",
          txtList: [
            "10:30-11:30 数字化国际物流时代的探索与布局",
            "嘉宾：周诗豪 运去哪创始人&CEO",
          ],
          imgList: [
            "https://attached-file.oss-cn-shanghai.aliyuncs.com/ailaworldImgs/siffa/where/where6.png",
          ],
          videoId: "7029d92eb300429ab63602a90029f51b",
          coverUrl:
            "https://vod.aila.site/7029d92eb300429ab63602a90029f51b/snapshots/1c60ac0c9bb644bbbe0d05da5d541fa0-00005.jpg",
        },
        {
          txtList: [
            "10:10-10:30 《国际物流产业数字化发展报告》解读",
            "嘉宾：徐凯 上海国际航运研究中心首席信息官",
          ],
          imgList: [
            "https://attached-file.oss-cn-shanghai.aliyuncs.com/ailaworldImgs/siffa/where/where12.png",
            "https://attached-file.oss-cn-shanghai.aliyuncs.com/ailaworldImgs/siffa/where/where.jpg",
          ],
        },
        {
          txtList: [
            "13:30-13:50 从招商局的诞生看今天的国际物流",
            "嘉宾：吕克俭 招商局创投总经理",
          ],
          imgList: [
            "https://attached-file.oss-cn-shanghai.aliyuncs.com/ailaworldImgs/siffa/where/where4.jpg",
          ],
        },
        {
          txtList: [
            "13:50-14:10 技术助力货代行业数字化升级",
            "嘉宾：梁佛木 腾讯企点副总经理",
          ],
          imgList: [
            "https://attached-file.oss-cn-shanghai.aliyuncs.com/ailaworldImgs/siffa/where/where2.jpg",
          ],
        },
        {
          title: "生态篇",
          txtList: [
            "14:10-14:50 【圆桌论坛】后疫情时代 全球供应链物流的变革与挑战",
            "嘉宾：周诗豪（主持）运去哪创始人&CEO",
            "滕亚辉 浙江省“四港”联盟秘书长",
            "杨向东 亚海航运执行董事",
            "马卡尔 新新运董事合伙人",
            "李娜 跨欧亚副总经理",
            "刘俊 上海铁甲CEO",
          ],
          imgList: [
            "https://attached-file.oss-cn-shanghai.aliyuncs.com/ailaworldImgs/siffa/where/where1.png",
          ],
          videoId: "8c95cd0bfe2343698dbef3ad18a91105",
          coverUrl:
            "https://vod.aila.site/8c95cd0bfe2343698dbef3ad18a91105/snapshots/f95198bc27fe4fb1a1d42ddfe3575b7e-00005.jpg",
        },
        {
          txtList: [
            "14:50-15:30 【圆桌论坛】资本视野下的国际物流产业新浪潮",
            "嘉宾：李忠桦（主持） 招商局创投创新协同部负责人",
            "郭山汕 红杉资本中国基金合伙人",
            "曾振宇 DCM董事合伙人",
            "王星石 源码资本合伙人",
            "汤和松 襄禾资本创始合伙人",
            "黄羽佳 Coatue副总裁",
          ],
          imgList: [
            "https://attached-file.oss-cn-shanghai.aliyuncs.com/ailaworldImgs/siffa/where/where16.png",
          ],
          videoId: "cb03e2e260e645ab9a3111f490d4b08f",
          coverUrl:
            "https://vod.aila.site/9f5c29e02fb04bdd8185f339e13f9df1/snapshots/6f5d7e8f69d5475fa590976bb3aca3f0-00005.jpg",
        },
        {
          txtList: [
            "15:30-16:10 【圆桌论坛】数字科技驱动国际物流创新与升级",
            "嘉宾：徐杨（主持） 运去哪联合创始人&COO",
            "俞萧 箱信CEO",
            "杨雪 宝船网副总经理",
            "庄志强 信号旗创始人&CEO",
            "罗刚强 鸭嘴兽CTO",
            "马震 荣易达总经理",
          ],
          imgList: [
            "https://attached-file.oss-cn-shanghai.aliyuncs.com/ailaworldImgs/siffa/where/where14.png",
          ],
          videoId: "e8830717f3b24c95a7153e476e78e5e1",
          coverUrl:
            "https://vod.aila.site/e8830717f3b24c95a7153e476e78e5e1/snapshots/95ec9ab661974f4b8c195724ac2a95e2-00005.jpg",
        },
        {
          txtList: [
            "16:10-16:50 【圆桌论坛】风口下的跨境电商物流 新生态与新格局",
            "嘉宾：薛嵩（主持） 运去哪企业发展部VP",
            "程曦雯 无忧达上海公司总经理",
            "陈柏华 泛鼎国际总裁",
            "李成浩 四海商舟副总经理",
            "杨勋 利通智能包裹（UBI）副总裁",
            "韩迎超 千亚国际物流执行董事",
            "吴石胆 大森林CMO",
          ],
          imgList: [
            "https://attached-file.oss-cn-shanghai.aliyuncs.com/ailaworldImgs/siffa/where/where5.png",
          ],
          videoId: "b19291de38184f2d8cb6ceb623995061",
          coverUrl:
            "https://vod.aila.site/b19291de38184f2d8cb6ceb623995061/snapshots/8a6c502c7a9b41229149c8a76f35c4af-00005.jpg",
        },
      ],
    };
  },
  methods: {
    playVideo(id) {
      if (this.IS_LOGIN) {
        if (this.USER_INFO.aila_no || this.USER_INFO.aila_no === null) {
          this.$refs.playVideo.startPlay(id);
          this.reportLogs(id);
        } else {
          this.dialogVisible = true;
        }
      } else {
        this.$GLOBALEVENT.$emit("SHOWGLOBALLOGIN", {
          waitTodo: () => {
            this.$router.push({
              path: "/whereDelivery",
            });
          },
        });
      }
    },
    async reportLogs(id) {
      let nowTime = moment(new Date()).unix();
      let obj = {
        user_id: this.USER_INFO.id,
        aliyun_id: id,
        time: nowTime,
      };
      let params = {
        type: "yunSpecial",
        data: JSON.stringify(obj),
      };
      let data = await this.$store.dispatch(
        "API_index/reportLogs",
        params
      );
    },
  },
  mounted() {
    let arr = [];
    this.list2.forEach((it) => {
      if (it.imgList) {
        it.imgList.forEach((item) => {
          arr.push(item);
        });
      }
    });
    this.srcList = this.list1.concat(arr);
  },
};
</script>

<style lang="less" scoped>
.detail {
  background: #fff;
  width: 100%;
  padding: 24px 0;
  margin-bottom: 24px;
}
.detailContent {
  .title {
    font-size: 18px;
    font-weight: bold;
    margin: 12px 0;
  }
  .text {
    color: #666;
    margin: 6px auto;
    width: 516px;
  }
  .img {
    margin: 12px 0;
  }
}
.palyVideo {
  width: 800px;
  height: 450px;
  position: relative;
  margin: 0 auto;
  .coverImg {
    width: 800px;
    height: 450px;
    position: absolute;
    left: 0;
    top: 0;
  }
}

.mark {
  width: 800px;
  height: 450px;
  background: rgba(0, 0, 0, 0.2);
  position: absolute;
  top: 0;
  left: 0;
  z-index: 100;
  text-align: center;
  .el-icon-video-play {
    font-size: 52px;
    color: #ececee;
    line-height: 450px;
  }
}
.mark:hover {
  background: rgba(0, 0, 0, 0.6);
}
.mark:hover .el-icon-video-play {
  color: #fff;
}

.tips {
  font-size: 16px;
  line-height: 24px;
}
.width1 {
  width: 516px;
}
.width2 {
  width: 415px;
}
</style>